<template>
  <div class="container">
    <div class="content">
      <div class="title">
        <div class="title-top">
          <img src="../assets/call/icon.png"
               class="img-icon"
               alt="">
          <h1 class="title-h1">CONTACT US</h1>
        </div>
        <div class="title-bottom"
             v-if="$store.state.isZH"
             id="title">
          联系我们
        </div>
      </div>
      <div class="contact-way">

        <el-row type="flex"
                justify="center"
                align="middle"
                v-for="item in list"
                :key="item.icon">
          <el-col :span="24">
            <div class="item">
              <div class="icon"><img class="img"
                     :src="item.icon"
                     alt=""></div>
              <div class="phone">
                <div class="size">{{ item.text }}</div>
                <div>{{ item.value }}</div>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex"
                justify="center"
                align="middle">
          <el-col :span="24">
            <div class="item">
              <div class="icon"><img class="img"
                     :src="email.icon"
                     alt=""></div>
              <div class=" phone">
                <div class="size"
                     :style="!$store.state.isZH ? 'margin-right: 2%': ''">{{ email.text }}</div>
                <div>
                  <div> {{ email.value }}</div>
                  <div> {{ email2 }}</div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex"
                justify="center"
                align="middle">
          <el-col :span="24">
            <div class="item">
              <div class="icon"><img class="img"
                     :src="address.icon"
                     alt=""></div>
              <div class=" address">
                <div class="size"
                     :style="!$store.state.isZH ? 'margin-right: 2%': ''">{{ address.text }}</div>
                <div> {{ address.value }}</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CallUS',
  data () {
    return {
      email2: 'sales@originbiotech.com.cn'

    }
  },
  computed: {
    list () {
      return [
        {
          icon: require('../assets/call/phone.png'),
          text: this.$t('contact.phone'),
          value: this.$t('contact.tel')
        },
        {
          icon: require('../assets/call/icon3.png'),
          text: this.$t('contact.fax'),
          value: this.$t('contact.faxVal')
        }
      ]
    },
    address () {
      return {
        icon: require('../assets/call/place.png'),
        text: this.$t('contact.address'),
        value: this.$t('contact.addressVal')
      }
    },
    email () {
      return {
        icon: require('../assets/call/email.png'),
        text: this.$t('contact.email'),
        value: this.$t('contact.emailVal')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  .content {
    width: 100%;
    color: #fff;
    height: 100%;
    padding: 22% 15% 0;
    background: url("../assets/call/bgc.png") no-repeat;
    background-size: cover;
    box-sizing: border-box;
    .title {
      color: #fff;
      padding-bottom: 20%;
      box-sizing: border-box;
      .img-icon {
        height: 65px;
        padding-right: 6%;
      }
      .title-top {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      .title-h1 {
        font-size: 70px;
      }
      .title-bottom {
        display: flex;
        justify-content: flex-end;
        font-size: 47px;
        font-weight: bold;
      }
    }
    .contact-way {
      padding-bottom: 35%;
      .item {
        display: flex;
        padding: 8px 0;
        .icon {
          padding-right: 1.5%;
          display: flex;
          align-items: center;
          .img {
            width: 30px;
          }
        }
        .phone {
          display: flex;
          align-items: center;
          border-bottom: 1px solid #fff;
          font-size: 21px;
        }
        .address {
          display: flex;
          align-items: center;
          font-size: 21px;
        }
      }
    }
  }
}
@media only screen and (max-width: 500px) {
  .container {
    .content {
      padding: 22% 6% 0;
      box-sizing: border-box;
      .title {
        padding-top: 20%;
        .title-top {
          .title-h1 {
            font-size: 28px;
          }
        }
        #title {
          font-size: 22px;
          font-weight: 600;
        }
        .img-icon {
          width: 30px !important;
          height: 30px;
        }
      }
      .contact-way {
        padding-bottom: 25%;
        .item {
          display: flex;
          padding: 8px 0;
          .icon {
            padding-right: 1.5%;
            display: flex;
            align-items: center;
            .img {
              width: 25px;
            }
          }
          .phone {
            font-size: 12px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #fff;
            padding-bottom: 2%;
            .size {
              width: 46px;
            }
          }
          .address {
            font-size: 12px;
            display: flex;
            align-items: center;
            padding-bottom: 2%;
            .size {
              width: 50px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .container {
    .content {
      padding: 22% 6% 0;
      box-sizing: border-box;
      .title {
        padding-top: 20%;
        .title-h1 {
          font-size: 45px;
        }
        // .title-top {
        // }

        .title-bottom {
          font-size: 28px;
        }
        .img-icon {
          width: 50px;
          height: 30px;
        }
      }
    }
  }
}
</style>